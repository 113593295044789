const TicketExportsNew = (configuration, customFields) =>
  [
    // Informations générales sur le ticket
    { label: 'ticketNumber', key: 'number', show: true },
    { label: 'createdDate', key: 'created_at', show: true },
    { label: 'priority', key: 'priorities', show: true },
    { label: 'status', key: 'status', show: true },
    { label: 'title', key: 'title', show: true },
    { label: 'description', key: 'description', show: true },
    { label: 'typology', key: 'typology', show: true },
    { label: 'creator', key: 'creator', show: true },

    // Localisation et gestion des lieux
    { label: 'locationName', key: 'location_name', show: true },
    { label: 'exportLocation_reference', key: 'location_reference', show: true },
    { label: 'address', key: 'address', show: true },
    { label: 'zone', key: 'zone', show: true },

    // Client / Utilisateur
    { label: 'clientName', key: 'client_name', show: configuration.isContractor ? true : false },
    { label: 'managersTitle', key: 'managers', show: true },
    { label: 'contacts', key: 'contacts', show: true },

    // Travaux et équipements
    { label: 'skills', key: 'skills', show: true },
    { label: 'equipmentName', key: 'equipment_name', show: true },

    // Interventions et planifications
    { label: 'contractors', key: 'contractors', show: true },
    { label: 'programmedVisitDate', key: 'planned_dates', show: true },
    { label: 'plannedDatesContractor', key: 'planned_dates_contractor', show: configuration.isContractor ? true : false },
    { label: 'interventionDate', key: 'intervention_dates', show: true },
    { label: 'interventionDatesContractor', key: 'intervention_dates_contractor', show: configuration.isContractor ? true : false },
    { label: 'attributionTicketIntervener', key: 'assignation_date', show: configuration.isClient ? true : false },
    { label: 'indicationScheduledVisitDate', key: 'input_planned_date', show: configuration.isClient ? true : false },
    { label: 'TotalDurationIntervention', key: 'intervention_time', show: true },

    // Devis
    { label: 'quotesAmount', key: 'quote_amount', show: true },
    { label: 'quotesAmountContractor', key: 'quotes_amount_contractor', show: configuration.isContractor ? true : false },
    { label: 'quotesReference', key: 'quote_reference', show: true },
    { label: 'quotesReferenceContractor', key: 'quotes_reference_contractor', show: configuration.isContractor ? true : false },
    { label: 'amountQuotesUnvalidated', key: 'amount_quoted_unvalidated', show: true },
    { label: 'dateValidationQuotes', key: 'validation_quotes_date', show: true },

    // Coûts et facturation
    { label: 'htInterventionAmount', key: 'invoice_amount', show: true },
    { label: 'invoiceAmountContractor', key: 'invoice_amount_contractor', show: configuration.isContractor ? true : false },
    { label: 'invoiceReference', key: 'invoice_reference', show: true },
    { label: 'invoiceReferenceContractor', key: 'invoice_reference_contractor', show: configuration.isContractor ? true : false },
    { label: 'AmountIntervention', key: 'total_spent', show: true },

    // Historique et suivi
    { label: 'ticketTermination', key: 'finished_at', show: true },
    { label: 'ticketCloseDate', key: 'closed_at', show: true },

    // Comptabilité et domaines (optionnel)
    { label: 'totalCostsConsumptionsStock', key: 'stock_costs', show: configuration?.feature?.inventory ? true : false },
    { label: 'menuItemComptability', key: 'facture_state', show: configuration.isContractor ? false : true },
    { label: 'domains', key: 'domains', show: configuration.job_domains?.length ? true : false },

    // Champs personnalisés
    ...(customFields?.length
      ? customFields.map((field) => ({
          label: field.label,
          key: field._id,
          show: true
        }))
      : []),
  ].filter((e) => e.show);

export default TicketExportsNew;
