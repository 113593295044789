import { Box, IconButton } from '@material-ui/core';
import { attachClient } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';

import Avatar from 'components/Avatar';
import InterfaceIndicator from 'components/Interface/InterfaceIndicator';
import FAIcon from 'components/ui/FAIcon';
import yellowpages from 'assets/svg/yellowpages.svg';
import linkedin from 'assets/svg/linkedin.svg';
import { useAuth } from 'hooks/useAuth';
import useStyles from 'layouts/entities/Preview.styles';
import { truncateText } from 'utils/uiUtils';
import { useEntity } from 'contexts/entities/entityContext';
import { useIsBelowMd } from 'hooks/useMQ';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import Tooltip from 'components/ToolTip';

export default function ContractorPreview({ element, isActive }) {
  const auth = useAuth();
  const classes = useStyles();
  const { scheduler, tab, locationsPrio = [] } = useEntity();
  const name = element.name || element.companyName;
  const { requestStatus, dispatch } = useAsyncDispatch();
  const portalLoading = requestStatus === 'loading';
  const connect = (e, attach) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      attachClient,
      { attach, parentType: '_contractor', type: 'contractor' },
      {},
      { id: element._id }
    );
  };
  const { t } = useTranslation();

  const isBelowMd = useIsBelowMd();

  const isMarketplace = tab === 'public';

  const portal =
    tab !== 'public' && auth.interface.isMaster
      ? !auth.user.interfaces.find((i) => i._company._id === element._id)
        ? 1
        : 2
      : null;

  return (
    <>
      <Box
        display={'flex'}
        style={{ width: '100%' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
        >
          <Box className={classes.avatar}>
            <Box position={'relative'}>
              <Avatar
                entity={{
                  name,
                  avatar: element.avatar
                }}
                shadow={false}
                size={'small2'}
              />
              <InterfaceIndicator _user={element._user} />
            </Box>
          </Box>
          <Box
            flexGrow={1}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Box className={classes.l}>
              {
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  flexGrow={1}
                  minWidth={0}
                >
                  {element.ref && <span className={classes.reference}>#{element.ref}</span>}
                  {
                    <span
                      className={classes.title}
                      style={scheduler && element.partner ? { color: element.partner.color } : {}}
                    >
                      {name}
                      {(locationsPrio || []).includes(element._id) && (
                        <Tooltip
                          position={'right'}
                          title={t('intervenerAlreadyAssigned')}
                        >
                          <span style={{ display: 'inline-flex' }}>
                            <FAIcon
                              collection={'fas'}
                              icon={'star'}
                              style={{ color: '#fbb034', fontSize: 10, marginLeft: 4 }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </span>
                  }
                  {!!element._jobs?.length && (
                    <strong className={classes.reference}>
                      {t(element._jobs[0].name)}
                      {element._jobs?.length > 1 ? `, ${element._jobs?.length - 1} autres` : ''}
                    </strong>
                  )}
                </Box>
              }

              <Box
                display={'flex'}
                alignItems="center"
              >
                {element.partner &&
                  auth.interface._company._id === (element._client || element._contractor) && (
                    <>
                      {element.isExclu !== null && element.isExclu === true && (
                        <strong style={{ marginLeft: 6 }}>{t('exclusiveTitle')}</strong>
                      )}
                      {element.isExclu !== null && element.isExclu === false && (
                        <strong style={{ marginLeft: 6 }}>{t('recommendedTitle')}</strong>
                      )}
                    </>
                  )}

                {element.ratings?.length > 0 && isMarketplace && (
                  <div
                    style={{
                      height: 10,
                      lineHeight: 10,
                      display: 'flex',
                      alignItems: 'center',
                      color: '#ff9100',
                      marginRight: '0.5em',
                      fontWeight: 500,
                      fontSize: 12,
                      letterSpacing: '0.1em'
                    }}
                  >
                    <FAIcon
                      collection="fas"
                      icon="star-sharp"
                      size="xs"
                      style={{ marginRight: 2 }}
                    />
                    {Number(element?.ratings_avg).toPrecision(3)} ({element.ratings?.length})
                  </div>
                )}

                {isMarketplace && (
                  <>
                    {element.score > 0 &&
                      (isBelowMd ? (
                        <FAIcon
                          collection="fas"
                          icon="crown"
                          size="small"
                          style={{ color: 'rgb(49, 181, 89)' }}
                        />
                      ) : (
                        <div
                          style={{
                            color: 'rgb(49, 181, 89)',
                            height: 10,
                            lineHeight: 10,
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '0.5em',
                            fontWeight: 500,
                            fontSize: 12,
                            letterSpacing: '0.1em'
                          }}
                        >
                          <FAIcon
                            collection="fas"
                            icon="crown"
                            size="xs"
                            style={{ marginRight: 2 }}
                          />
                          {t('recommendedTitle').toLocaleUpperCase()}
                        </div>
                      ))}
                  </>
                )}
              </Box>
            </Box>
            {!!element.description?.length && (
              <Box className={classNames(classes.l, classes.description)}>
                {truncateText(element.description, 160)}
              </Box>
            )}

            {isMarketplace && (
              <Box
                display="flex"
                alignItems="center"
                style={{ width: '100%', marginTop: 6 }}
              >
                {element.web && (
                  <FAIcon
                    icon={'globe'}
                    collection={'fal'}
                    style={{ fontSize: 14, height: 14, width: 16 }}
                  />
                )}

                {element.linkedin && (
                  <img
                    src={linkedin}
                    style={{ height: 16, marginLeft: 4 }}
                    alt="LinkedIn Logo"
                  />
                )}

                {element.jaune && (
                  <img
                    src={yellowpages}
                    style={{ height: 16, marginLeft: 4 }}
                    alt="Page jaune Logo"
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        {
          <Box>
            {!portalLoading && portal === 1 && (
              <IconButton onClick={(e) => connect(e, true)}>
                <FAIcon
                  collection={'fas'}
                  icon={'portal-enter'}
                />
              </IconButton>
            )}
            {!portalLoading && portal === 2 && (
              <IconButton
                onClick={(e) => connect(e, false)}
                className={classes.successLight}
              >
                <FAIcon
                  collection={'fas'}
                  icon={'portal-exit'}
                />
              </IconButton>
            )}
            {portalLoading && (
              <IconButton>
                <FAIcon
                  icon={'spinner'}
                  className={['fa-spin']}
                  collection={'fad'}
                />
              </IconButton>
            )}
          </Box>
        }
      </Box>
    </>
  );
}

export function ContractorItemPreview({ contractor }) {
  const classes = useStyles();

  return (
    <>
      {contractor && (
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          <strong>{contractor.name || contractor.companyName}</strong>
          {
            <Avatar
              className={classes.avatarSmall}
              entity={{
                name: contractor.name || contractor.companyName,
                avatar: contractor.avatar
              }}
              shadow={false}
              size={'xsmall'}
            />
          }
        </Box>
      )}
    </>
  );
}
