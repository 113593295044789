import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  badge: {
    ['& .MuiBadge-badge']: {
      color: 'white',
      background: theme.palette.primary.dark,
      top: 16,
      right: -16
    }
  },
  tabIndicator: {
    background: 'white'
  },
  tabs: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 0,
      flexGrow: 0
    }
  },
  top: ({ isDialog }) => ({
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    zIndex: 7
  }),
  appbar: ({ isDialog }) => ({
    borderRadius: theme.radius,
    background: theme.palette.primary.light,
    color: 'white',
    position: 'relative',
    [theme.breakpoints.down(768)]: {
      borderRadius: '12px 12px 12px 12px',
      position: 'fixed',
      top: 'auto',
      bottom: 2,
      width: '99%',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  }),
  tab: {
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRadius: theme.radius,
    boxShadow: theme.shadows[1],
    height: '100%',
    position: 'relative',
    zIndex: 1,
    minHeight: 400,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      borderRadius: `0px 0px ${theme.radius}px ${theme.radius}px`
    }
  },
  tabPadding: ({ isDialog }) => ({
    width: '100%',
    padding: 16
  }),
  tabNoPadding: {
    width: '100%',
    padding: 0
  },
  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 10px',
    alignItems: 'center',
    borderRadius: `${theme.radius}px ${theme.radius}px 0px 0px`
  },
  headerIcon: {
    marginRight: 16,
    fontSize: 16
  },
  underlyingComponent: {
    background: 'transparent',
    padding: 10,
    boxShadow: 'none'
  }
}));
