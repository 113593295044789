import { Box, Chip, Button, Breadcrumbs, Typography, Popover } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import { makeStyles } from '@material-ui/core/styles';
import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'layouts/entities/Preview.styles';
import { ClientItemPreview } from 'entities/Client/ClientPreview';
import { MaintenanceItemPreview } from 'entities/Maintenance/MaintenancePreview';
import { useConfiguration } from 'hooks/useConfiguration';
import { PeriodsMenu } from '../../components/timeline/Timeline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash-es';
import useKeys from '@flowsn4ke/usekeys';
import {
  critical_equipment,
  loss_ratio_equipment,
  LossRatioTooltip,
  operational_states_equipment,
  replacement_date_planned,
  warranty_equipment,
  WarrantyTooltip
} from 'lists/advance_indicator_equipment';
import Tooltip from 'components/ToolTip';

export default function EquipmentPreview({ element: { maintenance, ...element }, isActive, payload: { timeline } }) {
  const { setSublistParent } = useEntity();
  const classes = useStyles();
  const configuration = useConfiguration();
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();

  const operational_state = element?.operational_state || 'NOT_SPECIFIED';
  const replacement_date = element?.replacement_date || 'NOT_SPECIFIED';
  const warranties = element?._guarantees || 'NOT_SPECIFIED';

  const operational = operational_states_equipment.find((e) => String(e.value) === String(operational_state));
  const replacement_planned = replacement_date_planned(replacement_date);
  const warranty = warranty_equipment(warranties);
  const criticity = critical_equipment(element?.criticity);
  const tickets = element?._tickets || [];
  const loss_ratio = loss_ratio_equipment(tickets);

  return (
    <>
      <Box
        style={{ width: '100%' }}
        onMouseEnter={(e) => setIsHover(true)}
        onMouseLeave={(e) => setIsHover(false)}
      >
        {timeline && isHover && <PeriodsMenu maintenance={maintenance} />}
        <div className="flex">
          <div className="w-full flex gap-1.5 items-center">
            {(element.number || element.name) && (
              <>
                {element.name && (
                  <span className="font-[500] text-[14px] overflow-ellipsis text-sm">
                    {!!maintenance && (
                      <FAIcon
                        style={{ marginRight: 4 }}
                        icon={'wrench-simple'}
                        collection={'fal'}
                        size={'small'}
                      />
                    )}
                    {element.name}
                  </span>
                )}
              </>
            )}
            {!element.name && (
              <span className="font-[500] text-[14px] overflow-ellipsis text-sm">{t('noEquipmentName')}</span>
            )}
            {!maintenance && !!element._equipments?.length && (
              <Button
                variant={'outlined'}
                size={'small'}
                className={classes.subButton}
                onClick={(e) => [e.stopPropagation(), e.preventDefault(), setSublistParent(element)]}
              >
                {element._equipments?.length}
                <FAIcon
                  style={{ marginLeft: 4 }}
                  icon={'wrench-simple'}
                  collection={'fal'}
                  size={'small'}
                />
              </Button>
            )}
            {!!criticity && configuration.isClient && (
              <Tooltip title={t(criticity.label)}>
                <div className={`w-6 h-6`}>
                  <FAIcon
                    icon={criticity?.icon}
                    collection={criticity?.collection}
                    size="medium"
                    className={`hover:${criticity.hoverColor} ${criticity.color} rounded-full`}
                  />
                </div>
              </Tooltip>
            )}
          </div>

          {/* IAE */}
          <div className={`gap-1.5 items-center justify-end ${!configuration.isClient && 'mr-2'} hidden lg:flex`}>
            {!timeline && (
              <>
                {operational?.value !== 'FUNCTIONAL' && operational?.value !== 'NOT_SPECIFIED' && (
                  <Tooltip title={t(operational?.label)}>
                    <div className="p-1.5">
                      <FAIcon
                        icon={operational.icon}
                        collection="fas"
                        size={'medium'}
                        className={`hover:${operational.hoverColor} ${operational.color} rounded-full`}
                      />
                    </div>
                  </Tooltip>
                )}

                {configuration.isClient &&
                  replacement_planned?.value !== 'NOT_REQUIRED' &&
                  replacement_planned?.value !== 'NOT_SPECIFIED' && (
                    <Tooltip title={t(replacement_planned.label)}>
                      <div className="p-1.5">
                        <FAIcon
                          icon={replacement_planned.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${replacement_planned.hoverColor} ${replacement_planned.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                  )}

                {configuration.isClient && warranty?.value !== 'NOT_SPECIFIED' && warranty?.value !== 'ACTIVE' && (
                  <Tooltip
                    title={<WarrantyTooltip warranty={warranty} />}
                    position="bottom"
                  >
                    <div className="p-1.5">
                      <FAIcon
                        icon={warranty.icon}
                        collection="fad"
                        size={'medium'}
                        className={`hover:${warranty.hoverColor} ${warranty.color} rounded-full`}
                      />
                    </div>
                  </Tooltip>
                )}
                {configuration.isClient && loss_ratio?.value !== 'LOSS_RATIO_LOW' && (
                  <div>
                    <Tooltip
                      title={<LossRatioTooltip loss_ratio={loss_ratio} />}
                      position="bottom"
                    >
                      <div className="p-1.5">
                        <FAIcon
                          icon={loss_ratio.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${loss_ratio.hoverColor} ${loss_ratio.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
          {!timeline && (
            <Box>
              <ClientItemPreview client={element._client} />
            </Box>
          )}
        </div>

        {!timeline && element._location && (
          <BreadcrumbsLocation
            element={element._location}
            sublistParentKey={'_parentLocations'}
          />
        )}

        <Box className={classes.l}>
          <Box>{element._location?.address?.fullAddress || element._location?.name}</Box>
          <Box>
            {element._category && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._category.name}
              />
            )}
            {element._subcategory && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._subcategory.name}
              />
            )}
          </Box>
        </Box>

        {configuration.isClient && maintenance?._id && <MaintenanceItemPreview maintenance={maintenance} />}
      </Box>
    </>
  );
}

function BreadcrumbsLocation({ element, sublistParentKey }) {
  const list = useMemo(
    () => (!isArray(element[sublistParentKey]) ? [] : [...element[sublistParentKey]].reverse()),
    [element]
  );
  const k = useKeys();
  const classes = stylesBreadCrumbsLocation();

  return (
    !!element.name?.trim()?.length && (
      <Box className={classes.root}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {list.map((_element, i) => (
            <Typography
              style={{ fontSize: 13 }}
              key={k(i)}
            >
              {_element.name}
            </Typography>
          ))}

          <Typography
            color="textPrimary"
            style={{ fontSize: 13, fontWeight: 'bold' }}
          >
            {element.name}
          </Typography>
        </Breadcrumbs>
      </Box>
    )
  );
}

const stylesBreadCrumbsLocation = makeStyles((_) => ({
  root: () => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0px 0px',
    width: 'auto',
    borderBottom: 'none',
    ['& .MuiBreadcrumbs-separator']: {
      marginLeft: 0,
      marginRight: 0
    }
  }),
  breadcrumb: {},
  icon: {
    color: '#bfc6ce',
    marginRight: 13
  }
}));
