
export const allStates = [
  'visit_devis',
  'validation',
  'validated',
  'dropped',
  'intervention',
  'intervention_finish',
  'finished',
  'closed',
  'canceled',
  'cashedby',
  'removed',
  'visit',
  'assigned',
  'waiting_contractor',
  'waitingplan',
  'toplan',
  'toreplan',
  'proposed',
  'waiting',
  'opened',
  'draft',
  'expired',
  'accepted',
  'declined',
  'created'
];

export const statesOngoing = [
  'draft',
  'opened',
  'waiting',
  'proposed',
  'assigned',
  'visit',
  'validation',
  'validated',
  'intervention',
  'finished',
  'toreplan',
  'visit_devis',
  'intervention_finish'
];

export const statesClosed = ['closed', 'declined'];

const bookmarkColors = {
  toplan: 'orange',
  toreplan: 'red', // lime -> red
  waitingplan: 'black-blue',
  opened: 'slate',
  waiting: 'black-blue', // yellow -> black (a assigner)
  validation: 'orange-clair',
  assigned: 'orange', // pink to -> orange
  visit: 'blue-sky',
  finished: 'green', // teal -> green
  validation_waiting: 'light-orange',
  validation_plan: 'red',
  technicians: 'blue',

  // Compta
  to_complete: 'orange',
  to_check: 'red',
  to_waiting: 'purple',
  to_valid: 'blue',
  analyse: 'black-blue',
  reserve: 'light-orange',
  to_send: 'blue-sky',
  sent: 'green',
  available: 'blue-sky',

};

export const getBookmarks = ({ scheduler = false, calendar = false, auth, isCompta }) => {
  if (scheduler) {
    return [
      { key: 'toplan', color: bookmarkColors.toplan, label: 'toPlan' },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toReplan' },
      { key: 'waitingPlan', color: bookmarkColors.waitingplan, label: 'waitingToBeReplaned' }
    ];
  } else if (calendar) {
    return [{ key: 'reset', color: bookmarkColors.technicians, label: 'allTechnicians' }];
  } else if (isCompta) {
    return [
      { key: 'to_complete', color: bookmarkColors.to_complete, label: 'toComplete' },
      { key: 'to_check', color: bookmarkColors.to_check, label: 'toCheck' },
      { key: 'to_waiting', color: bookmarkColors.to_waiting, label: 'waiting' },
      { key: 'to_valid', color: bookmarkColors.to_valid, label: 'toValid' },
      { key: 'analyse', color: bookmarkColors.analyse, label: 'analyse' },
      { key: 'reserve', color: bookmarkColors.reserve, label: 'reserve' },
      { key: 'to_send', color: bookmarkColors.to_send, label: 'toSend' },
      { key: 'sent', color: bookmarkColors.sent, label: 'transmitted' },
      { key: 'available', color: bookmarkColors.available, label: 'available' }
    ].filter((bookmark) => Object.keys(bookmark).length > 0);
  } else {
    return [
      auth.interface.isClient ? { key: 'opened', color: bookmarkColors.opened, label: 'toValid' } : {},
      { key: 'waiting', color: bookmarkColors.waiting, label: 'toBeAssignated' },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toBeReplaned' },
      { key: 'validation', color: bookmarkColors.validation, label: 'quoteToBeValidated' },
      auth.interface.isContractor
        ? { label: 'quoteAtt', color: bookmarkColors.validation_waiting, key: 'validation_waiting' }
        : {},
      auth.interface.isContractor
        ? { label: 'quotePl', color: bookmarkColors.validation_plan, key: 'validation_plan' }
        : {},
      { key: 'assigned', color: bookmarkColors.assigned, label: 'waitingForInfo' },
      { key: 'visit', color: bookmarkColors.visit, label: 'inProgress' },
      { key: 'finished', color: bookmarkColors.finished, label: 'toBeClosed' }
    ].filter((bookmark) => Object.keys(bookmark).length > 0);
  }
};

export const sortLabel = [
  'creationDateMostRecent',
  'creationDateLeastRecent',
  'updateDateMostRecent',
  'updateDateLeastRecent',
  'ascendingAlphabetical',
  'descendingAlphabetical'
];

export function getColor(color) {
  switch (color) {
    case 'slate':
      return {
        activeIconColor: 'text-[#7f8c8d]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#7f8c8d]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#7f8c8d]',
        activeBackgroundColor: 'bg-[#7f8c8d]'
      };
    case 'red':
      return {
        activeIconColor: 'text-[#ce4413]', //  text-red-500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ce4413]', // 600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ce4413]', //border-red-500
        activeBackgroundColor: 'bg-[#ce4413]' // bg-red-500
      };
    case 'light-red':
      return {
        activeIconColor: 'text-[#e63d53]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#e63d53]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#e63d53]',
        activeBackgroundColor: 'bg-[#e63d53]'
      };
    case 'green':
      return {
        activeIconColor: 'text-[#31b55a]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#31b55a]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#31b55a]',
        activeBackgroundColor: 'bg-[#31b55a]'
      };
    case 'blue':
      return {
        activeIconColor: 'text-blue-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-blue-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-blue-500',
        activeBackgroundColor: 'bg-blue-500'
      };
    case 'blue-sky':
      return {
        activeIconColor: 'text-[#1fadda]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#1fadda]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#1fadda]',
        activeBackgroundColor: 'bg-[#1fadda]'
      };
    case 'yellow':
      return {
        activeIconColor: 'text-yellow-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-yellow-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-yellow-500',
        activeBackgroundColor: 'bg-yellow-500'
      };
    case 'orange':
      return {
        activeIconColor: 'text-[#ee754a]', // 500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ee754a]', // 600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ee754a]',
        activeBackgroundColor: 'bg-[#ee754a]'
      };
    case 'light-orange':
      return {
        activeIconColor: 'text-[#ef7a1f]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#ef7a1f]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#ef7a1f]',
        activeBackgroundColor: 'bg-[#ef7a1f]'
      };
    case 'pink':
      return {
        activeIconColor: 'text-[#fc427b]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#fc427b]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#fc427b]',
        activeBackgroundColor: 'bg-[#fc427b]'
      };
    case 'teal':
      return {
        activeIconColor: 'text-teal-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-teal-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-teal-500',
        activeBackgroundColor: 'bg-teal-500'
      };
    case 'black-blue':
      return {
        activeIconColor: 'text-[#2c3e50]', // text-500
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#2c3e50]', // text--600
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#2c3e50]', // border--500
        activeBackgroundColor: 'bg-[#2c3e50]' // bg-500
      };
    case 'indigo':
      return {
        activeIconColor: 'text-indigo-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-indigo-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-indigo-500',
        activeBackgroundColor: 'bg-indigo-500'
      };
    case 'lime':
      return {
        activeIconColor: 'text-lime-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-lime-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-lime-500',
        activeBackgroundColor: 'bg-lime-500'
      };
    case 'purple':
      return {
        activeIconColor: 'text-[#8e44ad]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#8e44ad]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#8e44ad]',
        activeBackgroundColor: 'bg-[#8e44ad]'
      };
    case 'dark-gray':
      return {
        activeIconColor: 'text-[#4f5b62]',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-[#4f5b62]',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-[#4f5b62]',
        activeBackgroundColor: 'bg-[#4f5b62]'
      };
    case 'sky':
      return {
        activeIconColor: 'text-sky-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-300',
        activeTextColor: 'text-sky-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-sky-500',
        activeBackgroundColor: 'bg-sky-500'
      };
    default:
      return {
        activeIconColor: 'text-amber-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-[#4f5b62]',
        activeTextColor: 'text-amber-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-amber-500',
        activeBackgroundColor: 'bg-amber-500'
      };
  }
}