import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import 'chart.js/auto';

import Tabs from 'components/tabs/Tabs';
import { useIsBelowMd, useIsBelowXl } from 'hooks/useMQ';
import FAIcon from 'components/ui/FAIcon';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { useConfiguration } from 'hooks/useConfiguration';

import formatAnalyticsDatasets from 'utils/analytics/formatAnalyticsDatasetsAndTimeSort';

import { formatCurrency } from 'utils/formatCurrency';
import { formatNumber } from 'utils/formatNumber';
import StatCard from 'components/charts/StatCard';
import LinesChart from 'components/charts/LinesChart';
import BarsChart from 'components/charts/BarsChart';
import HorizontalBarsChart from 'components/charts/HorizontalBarsChart';
import CamembertChart from 'components/charts/CamembertChart';
import { useTranslation } from 'react-i18next';
import { colors } from 'constants/colors';
import TableChart from 'components/charts/TableChart';
import { useHistory } from 'react-router-dom';
import StatCardPreventifs from 'components/charts/StatCardPreventifs';
import { truncateText } from 'utils/uiUtils';
import { interpolate_meter_readings_to_12_months_data_points } from 'utils/linear_interpolation';
import { isWithinInterval } from 'date-fns';
import { TYPOLOGY_OPTIONS } from 'settings/BudgetsConfiguration';
import { useAuth } from 'hooks/useAuth';
import Tooltip from 'components/ToolTip';

function generate_month_labels() {
  const labels = [];
  const today = new Date();
  const current_month = today.getMonth();
  const current_yeat = today.getFullYear();

  for (let i = 0; i < 12; i++) {
    const month = current_month - i;
    const year = current_yeat - (month < 0 ? 1 : 0);
    const formatted_month = (month < 0 ? month + 12 : month) + 1;
    const formatted_year = year.toString().slice(2);
    const formatted_label = `${formatted_month}/${formatted_year}`;
    labels.push(formatted_label);
  }

  labels.reverse();

  return labels;
}

function generate_datasets_from_consumptions_by_key(consumptions) {
  const datasets = Object.keys(consumptions).map((key) => {
    const readings = consumptions[key];
    return readings.slice(1);
  });

  return datasets;
}

function generate_datasetsLabels_from_consumptions_by_key(meters, consumptions, key) {
  const datasetsLabels = Object.keys(consumptions).map((consumption_id) => {
    const meter_with_key = meters.find((meter) => meter[`${key}_id`] === consumption_id);
    const label = meter_with_key ? meter_with_key[key].name : consumption_id;
    return label;
  });

  return datasetsLabels;
}

function group_readings_by_meter_id_and_interpolate_to_12_months(readings) {
  // 0. Group readings by meter_id
  const readingsByMeterId = readings.reduce((acc, reading) => {
    const meterId = reading.meter_id;
    if (!acc[meterId]) acc[meterId] = [];
    acc[meterId].push(reading);
    return acc;
  }, {});

  // 1. Sort all readings by date
  Object.values(readingsByMeterId).forEach((meterReadings) => {
    meterReadings.sort((a, b) => new Date(a.at) - new Date(b.at));
  });

  // 2. Compute consumption for each reading
  Object.values(readingsByMeterId).forEach((meterReadings) => {
    meterReadings.forEach((reading, index, array) => {
      const previousReading = array[index - 1];
      if (previousReading) {
        reading.consumption = reading.value - previousReading.value;
      } else {
        reading.consumption = null; // or handle the first reading differently
      }
    });
  });

  // 3. Interpolate readings to cover 12 months
  for (const meterId in readingsByMeterId) {
    readingsByMeterId[meterId] = interpolate_meter_readings_to_12_months_data_points(readingsByMeterId[meterId]);
  }

  return readingsByMeterId;
}

function group_consumptions_by_key(consumptions, pricings, key) {
  return Object.keys(consumptions).reduce((acc, meter_id) => {
    const readings = consumptions[meter_id];

    // If all readings are 0, we don't want to display the consumption
    // Thats means the meter has not been used during the period
    // So we don't want to display it
    if (readings.every((reading) => reading.value === 0)) return acc;

    const property = readings.at(-1)[key];

    acc[property] = acc[property] || [];

    for (let i = 0; i < readings.length; i++) {
      const reading = readings[i];

      // Find the pricing that was active at the time of the reading
      const pricing = pricings.find((pricing) => pricing.meter_id === meter_id);
      const pricing_has_end_date = pricing?.end ? new Date(pricing.end) : new Date();
      const interval_dates = { start: new Date(pricing?.start), end: pricing_has_end_date };
      const pricing_is_within_interval = pricing && isWithinInterval(new Date(reading.at), interval_dates);

      // If the pricing is not active at the time of the reading, the multiplier is 0
      const multiplier = pricing_is_within_interval ? pricing.price : 0;

      acc[property][i] = acc[property][i] || 0;
      acc[property][i] += reading.value * multiplier;
    }

    return acc;
  }, {});
}

function compute_sum_consumptions_by_key(consumptions, key) {
  return Object.keys(consumptions).reduce((acc, meter_id) => {
    const readings = consumptions[meter_id];
    // If all readings are 0, we don't want to display the consumption
    // Thats means the meter has not been used during the period
    // So we don't want to display it
    if (readings.every((reading) => reading.value === 0)) return acc;

    const property = readings.at(-1)[key];

    acc[property] = acc[property] || [];

    for (let i = 0; i < readings.length; i++) {
      const reading = readings[i];

      acc[property][i] = acc[property][i] || 0;
      acc[property][i] += reading.value;
    }

    return acc;
  }, {});
}

function generate_tooltipLabels_from_consumptions_by_key(consumptions_by_key, meters, sum_of_consumptions) {
  return Object.keys(consumptions_by_key).map((consumption_key) => {
    const meter = meters.find((meter) => meter.tag_id === consumption_key);

    const unit = meter?.unit;
    const value = sum_of_consumptions[consumption_key];
    return { unit, value };
  });
}

function Page({ children }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        borderRadius: 10
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
      >
        {children}
      </Grid>
      <div style={{ height: '20%' }} />
    </div>
  );
}

function TagContainer({ children }) {
  return (
    <Grid
      item
      xs={12}
      style={{ display: 'flex' }}
      alignItems="flex-start"
    >
      <Grid
        container
        spacing={2}
      >
        {children}
      </Grid>
    </Grid>
  );
}

function aggregateDatapoints(data) {
  return data.reduce((acc, curr) => {
    acc[curr.name] = [
      ...(acc[curr.name] || []),
      {
        count: curr.count,
        month: curr.month,
        year: curr.year
      }
    ];
    return acc;
  }, {});
}

function TagCard({
  icon,
  number,
  subtitle,
  tooltip,
  color,
  unitType = '',
  unitLabel = 'interventions',
  onClick,
  number_to_fixed = 0
}) {
  const isBelowXl = useIsBelowXl();

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={3}
    >
      <Box
        style={{
          height: 90,
          width: '100%',
          border: '1px solid #E4E4E4',
          borderRadius: 8,
          backgroundColor: '#fff'
        }}
        boxShadow={2}
        className={onClick ? 'hover:cursor-pointer' : ''}
        onClick={onClick}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            padding: isBelowXl ? '1em 1.5em' : '1em 2em',
            position: 'relative'
          }}
        >
          <Box
            style={{
              border: `1px solid #E4E4E4`,
              padding: '1em',
              borderRadius: '50%'
            }}
          >
            <FAIcon
              collection="fad"
              icon={icon}
              size="large"
            />
          </Box>
          <Box style={{ marginLeft: isBelowXl ? '0.7em' : '1.2em' }}>
            <Typography
              variant={isBelowXl ? 'h2' : 'h1'}
              style={{ fontWeight: 'bold', color, fontSize: '28px' }}
            >
              {isNaN(number) ? 0 : Number(number).toFixed(number_to_fixed)}
              {unitType}
              <span style={{ fontSize: '.4em' }}>&nbsp;&nbsp;{unitLabel}</span>
            </Typography>
            <Typography
              variant="h6"
              style={{ marginLeft: isBelowXl ? '0.2em' : '0' }}
            >
              {subtitle}
            </Typography>
          </Box>
          {tooltip && (
            <Box style={{ position: 'absolute', top: 0, right: 0 }}>
              <Tooltip
                title={tooltip}
                position="right"
              >
                <IconButton>
                  <FAIcon
                    collection="fas"
                    icon="info-circle"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
}

export default function DeskPage() {
  const auth = useAuth();
  const company = auth.interface._company;

  const isBelowMd = useIsBelowMd();
  const config = useConfiguration();
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    return [
      {
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FAIcon
              collection="fad"
              icon="files"
              style={{ marginRight: 10 }}
            />
            {t('interventions')}
          </div>
        ),
        content: <TicketDesk />
      },
      ...(config.isClient
        ? [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="heartbeat"
                    style={{ marginRight: 10 }}
                  />
                  {t('maintenancesTitle')}
                </div>
              ),
              content: <PreventifDesk />
            }
          ]
        : []),
      ...(auth?.interface?._role?.type === 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="gauge"
                    style={{ marginRight: 10 }}
                  />
                  {t('performance')}
                </div>
              ),
              content: <PerfDesk />
            }
          ]),
      {
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FAIcon
              collection="fad"
              icon={company?.currency?.icon || 'euro-sign'}
              style={{ marginRight: 10 }}
            />
            {config.isClient ? t('expenses') : t('activity')}
          </div>
        ),
        content: <BudgetDesk />
      },
      ...(auth?.interface?._role?.type === 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="user-helmet-safety"
                    style={{ marginRight: 10 }}
                  />
                  {t('intervenersTitle')}
                </div>
              ),
              content: <IntervenerDesk />
            }
          ]),
      ...(auth?.interface?._role?.type === 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="wrench-simple"
                    style={{ marginRight: 10 }}
                  />
                  {t('equipmentsTitle')}
                </div>
              ),
              content: <EquipmentDesk />
            }
          ]),
      ...(!config.feature.inventory || auth?.interface?._role?.type === 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="box-taped"
                    style={{ marginRight: 10 }}
                  />
                  {t('entityStockName')}
                </div>
              ),
              content: <StockDesk />
            }
          ]),
      ...(config.isClient || auth?.interface?._role?.type !== 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="wrench-simple"
                    style={{ marginRight: 10 }}
                  />
                  {t('clientsTitle')}
                </div>
              ),
              content: <ClientDesk />
            }
          ]),
      ...(!config.feature.meters || auth?.interface?._role?.type === 'user'
        ? []
        : [
            {
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FAIcon
                    collection="fad"
                    icon="meter"
                    style={{ marginRight: 10 }}
                  />
                  {t('consumptionsTitle')}
                </div>
              ),
              content: <ConsumptionDesk />
            }
          ])
    ];
  }, [config.isClient]);

  return (
    <>
      <Box style={{ width: '100%', height: '96%', padding: '10px' }}>
        <Tabs
          style={{
            backgroundColor: '#ffffff',
            marginBottom: '.8em',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            border: ''
          }}
          fullWidth={!isBelowMd}
          tabs={tabs}
        />
      </Box>
    </>
  );
}

function ClientDesk() {
  const { t } = useTranslation();

  return (
    <Page>
      <StatCard
        title={t('ticketsPerCustomer')}
        endpoint="analytic/ticketsCountByClient"
        chart={TableChart}
        column1Name={t('clientsTitle')}
        column2Name={t('interventions')}
      />
      <StatCard
        title={t('salesByCustomer')}
        endpoint="analytic/ingoingsTotalByClient"
        chart={TableChart}
        column1Name={t('clientsTitle')}
        column2Name={t('turnOverTitle')}
      />
    </Page>
  );
}

function EquipmentDesk() {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  return (
    <Page>
      <StatCard
        title={t('ticketsByEquipmentCategory')}
        endpoint="analytic/ticketsCountByEquipment"
        chart={TableChart}
        column1Name={t('category')}
        column2Name={t('interventions')}
      />
      <StatCard
        title={t('ticketsByEquipmentSubcategory')}
        endpoint="analytic/TicketsCountByEquipmentSubCategory"
        chart={TableChart}
        column1Name={t('subCategory')}
        column2Name={t('interventions')}
      />
      <StatCard
        style={{ pageBreakAfter: 'always' }}
        title={t('numberOfTicketsPerMonthEquipmentCategory')}
        endpoint="analytic/ticketsCountByMonthAndEquipment"
        formatDatasets={(data) => {
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('numberOfTicketsPerMonthEquipmentSubcategory')}
        endpoint="analytic/TicketsCountByMonthAndEquipmentSub"
        formatDatasets={(data) => {
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('expendituresByEquipmentCategory')}
        endpoint="analytic/OutgoingByEquipmentCategory"
        formatCount={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        chart={TableChart}
        column1Name={t('category')}
        column2Name={t('totalAmount')}
      />
      <StatCard
        title={t('expendituresByEquipmentSubcategory')}
        endpoint="analytic/OutgoingByEquipmentSubCategory"
        formatCount={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        chart={TableChart}
        column1Name={t('subCategory')}
        column2Name={t('totalAmount')}
      />
      <StatCard
        title={t('expendituresPerMonthEquipmentCategory')}
        endpoint="analytic/OutgoingByMonthAndEquipmentCategory"
        formatDatasets={(data) => {
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        formatYAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        chart={LinesChart}
      />
      <StatCard
        title={t('expendituresPerMonthEquipmentSubcategory')}
        endpoint="analytic/OutgoingByMonthAndEquipmentSubCategory"
        formatYAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        formatDatasets={(data) => {
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
    </Page>
  );
}

function IntervenerDesk() {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  return (
    <Page>
      <StatCard
        title={t('ticketsPerSpeaker')}
        endpoint="analytic/ticketsCountByContractor"
        chart={TableChart}
        column1Name={t('intervener')}
        column2Name={t('interventions')}
      />
      <StatCard
        title={t('expensesPerStakeholder')}
        endpoint="analytic/ticketOutgoingsByContractor"
        formatCount={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        chart={TableChart}
        column1Name={t('intervener')}
        column2Name={t('totalAmount')}
      />
      <StatCard
        title={t('availabilityByStakeholder')}
        endpoint="analytic/contractorsPercentDisponibility"
        formatCount={(count) => Number(count).toFixed(2) + '%'}
        chart={TableChart}
        column1Name={t('intervener')}
        column2Name="%"
      />
      <StatCard
        title={t('hoursPerSpeakerMonth')}
        endpoint="analytic/totalDurationInHoursByContractorAndMonth"
        formatYAxis={(n) => formatNumber(n)}
        formatDatasets={(data) => {
          data = aggregateDatapoints(data);
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={BarsChart}
      />
      <StatCard
        title={t('topTenBestSpeakers')}
        endpoint="analytic/TopRatedInterveners"
        formatCount={(count) => (
          <Rating
            value={count}
            readOnly
          />
        )}
        chart={TableChart}
        column1Name={t('intervener')}
        column2Name={t('stars')}
      />
      <StatCard
        title={t('flopTenLowestRatedSpeakers')}
        endpoint="analytic/LowestRatedInterveners"
        formatCount={(count) => (
          <Rating
            value={count}
            readOnly
          />
        )}
        chart={TableChart}
        column1Name={t('intervener')}
        column2Name={t('stars')}
      />
    </Page>
  );
}

function BudgetDesk() {
  const [tagData, setTagData] = useState({});

  const auth = useAuth();
  const locations = auth.interface?._locations || [];
  const is_not_user = auth?.interface?._role?.type !== 'user';
  const company = auth.interface._company;

  const { t } = useTranslation();

  const configuration = useConfiguration();

  useEffect(() => {
    axios
      .post(`${apiBaseURL}/analytic/FactureCountByState?last=3`, { locations })
      .then((res) => {
        setTagData(res.data.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page>
      {!!Object.keys(tagData)?.length && is_not_user && (
        <TagContainer>
          <TagCard
            icon="file-invoice"
            number={tagData?.to_complete}
            color={colors[0]}
            subtitle={t('toCompletelower')}
            unitLabel={t('invoiceslowercase')}
          />
          <TagCard
            icon="file-invoice"
            number={tagData?.to_check}
            color={colors[1]}
            subtitle={t('toCheckLowercase')}
            unitLabel={t('invoiceslowercase')}
          />
          <TagCard
            icon="file-invoice"
            number={tagData?.reserve}
            color={colors[2]}
            subtitle={t('reserveLowercase')}
            unitLabel={t('invoiceslowercase')}
          />
          <TagCard
            icon="file-invoice"
            number={tagData?.to_valid}
            color={colors[3]}
            subtitle={t('toValidate')}
            unitLabel={t('invoiceslowercase')}
          />
          <TagCard
            icon="file-invoice"
            number={tagData?.analyse}
            color={colors[4]}
            subtitle={t('processingInProgess')}
            unitLabel={t('invoiceslowercase')}
          />
          <TagCard
            icon="file-invoice"
            number={tagData?.to_send}
            color={colors[5]}
            subtitle={t('toTransmitlower')}
            unitLabel={t('invoiceslowercase')}
          />
        </TagContainer>
      )}
      {configuration?.feature?.budgets && is_not_user && (
        <>
          <StatCard
            noRangePicker
            title={t('Budgets')}
            endpoint="budgets/analytics"
            chart={HorizontalBarsChart}
            formatXAxis={(l) => `${l} %`}
            formatDatasets={(data) => ({
              datasets: data.map((dp) => dp.count).sort((a, b) => b - a),
              labels: data.map((dp) => `${dp.name} (${dp.year})`),
              colors: data.map((dp) => dp.color),
              tooltipLabels: data.map((dp) =>
                t('budgetsConsumptionLabel', {
                  total: formatCurrency({
                    number: dp.total,
                    locale: company?.currency?.locale,
                    currency: company?.currency?.code
                  }),
                  used: formatCurrency({
                    number: dp.used,
                    locale: company?.currency?.locale,
                    currency: company?.currency?.code
                  })
                })
              )
            })}
          />
          <StatCard
            noRangePicker
            title={t('budgetsByTypology')}
            chart={HorizontalBarsChart}
            endpoint="budgets/analytics-typology"
            formatXAxis={(l) => `${l} %`}
            formatDatasets={(data) => ({
              datasets: data.map((dp) => dp.count).sort((a, b) => b - a),
              labels: data.map((dp) =>
                dp.typology ? TYPOLOGY_OPTIONS.find((t) => t.value === dp.typology)?.label : t('othersLabel')
              ),
              tooltipLabels: data.map((dp) =>
                t('budgetsConsumptionLabel', {
                  total: formatCurrency({
                    number: dp.total,
                    locale: company?.currency?.locale,
                    currency: company?.currency?.code
                  }),
                  used: formatCurrency({
                    number: dp.used,
                    locale: company?.currency?.locale,
                    currency: company?.currency?.code
                  })
                })
              )
            })}
          />
        </>
      )}
      <StatCard
        title={t('totalExpensesByTicketType')}
        endpoint="analytic/ticketsTotalByType"
        chart={HorizontalBarsChart}
        formatXAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count),
          labels: data.map((dp) => dp.name)
        })}
      />
      <StatCard
        title={t('expensesTotalByJob')}
        endpoint="analytic/ticketsTotalByJob"
        chart={HorizontalBarsChart}
        formatXAxis={(n) =>
          formatCurrency({
            number: n.toFixed(0),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count),
          labels: data.map((dp) => dp.name)
        })}
      />
      <StatCard
        title={t('expensesByMonth')}
        endpoint="analytic/outgoingsByMonth"
        formatDatasets={(data) => {
          data = { Dépense: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('expensesByJobAndMonth')}
        endpoint="analytic/outgoingsByMonthAndJob"
        formatYAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        formatDatasets={(data) => {
          data = aggregateDatapoints(data);
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={BarsChart}
      />
      {is_not_user && (
        <>
          <StatCard
            title={t('expensesByLocations')}
            endpoint="analytic/outgoingsByLocation"
            formatCount={(n) =>
              formatCurrency({
                number: n.toFixed(2),
                locale: company?.currency?.locale,
                currency: company?.currency?.code
              })
            }
            chart={TableChart}
            column1Name={t('location')}
            column2Name={t('totalAmount')}
          />
          <StatCard
            title={t('totalExpensesByTicketPerMonth')}
            endpoint="analytic/IngoingsTotalByUrgencyAndMonth"
            formatDatasets={(data) => {
              data = aggregateDatapoints(data);
              const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
              return { datasets, datasetsLabels, labels };
            }}
            chart={LinesChart}
          />
        </>
      )}
    </Page>
  );
}

function PerfDesk() {
  const [tagData, setTagData] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    axios
      .post(`${apiBaseURL}/analytic/ticketPerformances?last=3`)
      .then((res) => {
        setTagData(res.data.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page>
      {!!Object.keys(tagData)?.length && (
        <TagContainer>
          <TagCard
            icon="chess-clock"
            number={tagData.lt24h?.toFixed(1)}
            color={colors[5]}
            subtitle={t('solvedInLessThan24h')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
          <TagCard
            icon="chess-clock"
            number={tagData.lt48h?.toFixed(1)}
            color={colors[4]}
            subtitle={t('solvedInLessThan48h')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
          <TagCard
            icon="chess-clock"
            number={tagData.lt7d?.toFixed(1)}
            color={colors[3]}
            subtitle={t('solvedIn7-Days')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
          <TagCard
            icon="chess-clock"
            number={tagData.lt30d?.toFixed(1)}
            color={colors[2]}
            subtitle={t('solvedIn30-Days')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
          <TagCard
            icon="chess-clock"
            number={tagData.gt30d?.toFixed(1)}
            color={colors[1]}
            subtitle={t('solvedIn30+Days')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
          <TagCard
            icon="chess-clock"
            number={tagData.not_finish?.toFixed(1)}
            color={colors[0]}
            subtitle={t('unresolvedlower')}
            unitLabel={t('ofTickets')}
            unitType="%"
            number_to_fixed={2}
          />
        </TagContainer>
      )}
      <StatCard
        title={t('tMOfResolutionPerEmergencyMonth')}
        endpoint="analytic/averageUrgenciesTicketTime"
        formatYAxis={(n) => `${n} jour${n > 1 ? 's' : ''}`}
        formatDatasetsLabels={(l) => ({ critical: 'Critique', high: 'Urgent', normal: 'Moyen', low: 'Peu urgent' }[l])}
        formatDatasets={(data) => {
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('averageAffectationByMonth')}
        endpoint="analytic/averageAssignedTicketTime"
        formatDatasets={(data) => {
          data = { Affectations: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        formatYAxis={(n) => `${n} jour${n > 1 ? 's' : ''}`}
        chart={LinesChart}
      />
      <StatCard
        title={t('avergeTicketCountByMonth')}
        endpoint="analytic/averageInterventionTicketTime"
        formatDatasets={(data) => {
          data = { Interventions: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        formatYAxis={(n) => `${n} jour${n > 1 ? 's' : ''}`}
        chart={LinesChart}
      />
      <StatCard
        title={t('averageTicketResolutionByMonth')}
        endpoint="analytic/averageFinishTicketTime"
        formatDatasets={(data) => {
          data = { Résolutions: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        formatYAxis={(n) => `${n} jour${n > 1 ? 's' : ''}`}
        chart={LinesChart}
      />
    </Page>
  );
}

function TicketDesk() {
  const [tagData, setTagData] = useState({});
  const { t } = useTranslation();

  const history = useHistory();

  const auth = useAuth();
  const locations = auth.interface?._locations || [];
  const is_not_user = auth?.interface?._role?.type !== 'user';

  const filterMapping = {
    waiting: { status: ['opened', 'waiting'], ongoing: false },
    being: { status: ['intervention', 'visit', 'visit_devis', 'intervention_finish'], ongoing: false },
    planned: { status: ['proposed', 'assigned', 'validation', 'validated'], ongoing: false },
    closing: { status: ['finished'], ongoing: false }
  };

  const handleClickTicketPage = (filterKey) => {
    const selectedFilter = filterMapping[filterKey] || {};
    const encodedFilter = encodeURIComponent(JSON.stringify(selectedFilter));
    history.push(`/tickets?filters=${encodedFilter}`);
  };

  useEffect(() => {
    axios
      .post(`${apiBaseURL}/analytic/ticketsCountByState?last=3`, { locations })
      .then((res) => {
        setTagData(res.data.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page>
      {!!Object.keys(tagData)?.length && (
        <TagContainer>
          <TagCard
            icon="chess-clock"
            number={tagData.waiting}
            color={colors[0]}
            subtitle={t('waitingLowercase')}
            tooltip={t('waitingValidation')}
            onClick={() => handleClickTicketPage('waiting')}
          />
          <TagCard
            icon="clock"
            number={tagData.being}
            color={colors[1]}
            subtitle={t('inProgresslower')}
            tooltip={t('inProgressTicket')}
            onClick={() => handleClickTicketPage('being')}
          />
          <TagCard
            icon="calendar"
            number={tagData.planned}
            color={colors[2]}
            subtitle={t('planned')}
            tooltip={t('plannedIntervention')}
            onClick={() => handleClickTicketPage('planned')}
          />
          <TagCard
            icon="clipboard-check"
            number={tagData.closing}
            color={colors[3]}
            subtitle={t('toBeClosedLower')}
            tooltip={t('toBeClosedIntervention')}
            onClick={() => handleClickTicketPage('closing')}
          />
        </TagContainer>
      )}
      <StatCard
        title={t('ticketsByJob')}
        endpoint="analytic/ticketsCountByJob"
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count),
          labels: data.map((dp) => dp.name)
        })}
        chart={CamembertChart}
      />
      {is_not_user && (
        <StatCard
          title={t('ticketsByLocation')}
          endpoint="analytic/ticketsCountByLocation"
          chart={TableChart}
          column1Name={t('location')}
          column2Name={t('interventions')}
        />
      )}
      <StatCard
        title={t('ticketsByMonth')}
        lineLabel={t('ticketsTotal')}
        endpoint="analytic/ticketsCountByMonth"
        formatDatasets={(data) => {
          data = { Tickets: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('ticketsByJobAndMonth')}
        endpoint="analytic/ticketsCountByMonthAndJob"
        formatDatasets={(data) => {
          data = aggregateDatapoints(data);
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={BarsChart}
      />
      {is_not_user && (
        <StatCard
          title={t('closedTicketsByManager')}
          endpoint="analytic/BigBrotherClosedTicket"
          formatDatasets={(data) => {
            const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
            return { datasets, datasetsLabels, labels };
          }}
          chart={LinesChart}
        />
      )}
      {is_not_user && (
        <StatCard
          title={t('ticketsByManager')}
          endpoint="analytic/BigBrotherPendingTicket"
          chart={TableChart}
          column1Name={t('manager')}
          column2Name={t('interventions')}
          scroll={{ maxHeight: 350, overflowY: 'scroll' }}
        />
      )}
    </Page>
  );
}

function StockDesk() {
  const [tagData, setTagData] = useState({});
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth?.interface?._company || {};

  useEffect(() => {
    axios
      .post(`${apiBaseURL}/analytic/InventoryOverview`)
      .then((res) => {
        setTagData(res.data.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page>
      {!!Object.keys(tagData)?.length && (
        <TagContainer>
          <TagCard
            icon={company?.currency?.icon || 'euro-sign'}
            number={tagData.totalAmount}
            color={colors[5]}
            unitLabel={company?.currency?.symbol ? company?.currency?.symbol : '€'}
            subtitle={t('valueOfStock')}
            tooltip={t('instantStockValuation')}
            number_to_fixed={2}
          />
        </TagContainer>
      )}
      <StatCard
        title={t('totalConsumptionCostPerMonth')}
        lineLabel={t('costs')}
        endpoint="analytic/consumptionOutgoingsByMonth"
        formatDatasets={(data) => {
          data = { Coút: data };
          const { datasets, datasetsLabels, labels } = formatAnalyticsDatasets(data);
          return { datasets, datasetsLabels, labels };
        }}
        chart={LinesChart}
      />
      <StatCard
        title={t('topTenMostExpensivePlaces')}
        endpoint="analytic/consumptionTopByLocation"
        formatCount={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        chart={TableChart}
        column1Name={t('location')}
        column2Name={t('totalAmount')}
      />
    </Page>
  );
}

function PreventifDesk() {
  const { t } = useTranslation();
  const [tagData, setTagData] = useState({});

  const auth = useAuth();
  const locations = auth.interface?._locations || [];

  const formatXAxis = (n) => (n < 1 ? n.toFixed(1) + '%' : n.toFixed(0) + '%');

  useEffect(() => {
    axios
      .post(`${apiBaseURL}/analytic/ReserveOverview`, { locations })
      .then((res) => {
        setTagData(res.data.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page>
      {!!Object.keys(tagData)?.length && (
        <TagContainer>
          <TagCard
            icon="tasks"
            number={tagData.total_high_reserve.toFixed(1)}
            color={colors[0]}
            subtitle={t('highReserve')}
            tooltip={t('numberOfTicketsWithReserve')}
          />
          <TagCard
            icon="tasks"
            number={tagData.total_medium_reserve.toFixed(1)}
            color={colors[1]}
            subtitle={t('mediumReserve')}
            tooltip={t('numberOfTicketsWithReserve')}
          />
          <TagCard
            icon="tasks"
            number={tagData.total_low_reserve.toFixed(1)}
            color={colors[2]}
            subtitle={t('lowReserve')}
            tooltip={t('numberOfTicketsWithReserve')}
          />
        </TagContainer>
      )}
      <StatCard
        noRangePicker
        title={t('percentageOfLastPeriodFinishedOnTimeByGenerator')}
        endpoint="analytic/mostPeriodsFinishOnTimeByMaintenanceGenerator"
        chart={HorizontalBarsChart}
        formatXAxis={(n) => formatXAxis(n)}
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count.toFixed(1)),
          labels: data.map((dp) => truncateText(dp.name, 20))
        })}
      />
      <StatCard
        noRangePicker
        title={t('percentageOfLastPeriodFinishedLateByGenerator')}
        endpoint="analytic/mostPeriodsFinishLateByMaintenanceGenerator"
        chart={HorizontalBarsChart}
        formatXAxis={(n) => formatXAxis(n)}
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count.toFixed(1)),
          labels: data.map((dp) => truncateText(dp.name, 20))
        })}
      />
      <StatCard
        noRangePicker
        title={t('percentagePrecisionOfExecutionTimeByGenerator')}
        endpoint="analytic/precisionOfExecutionByGenerator"
        chart={HorizontalBarsChart}
        formatXAxis={(n) => formatXAxis(n)}
        formatDatasets={(data) => ({
          datasets: data.map((dp) => dp.count.toFixed(1)),
          labels: data.map((dp) => truncateText(dp.name, 20))
        })}
      />
      <StatCardPreventifs
        title={t('percentageOfLastPeriodFinishedOnTimeByGeneratorAndContractors')}
        endpoint="analytic/mostContractorsFinishedOnTimeByGenerator"
        chart={HorizontalBarsChart}
        formatXAxis={(n) => formatXAxis(n)}
        formatDatasets={(data) => ({
          datasets: data[0].interveners.map((dp) => dp.count.toFixed(1)),
          labels: data[0].interveners.map((dp) => truncateText(dp.name, 15))
        })}
      />
    </Page>
  );
}

function ConsumptionDesk() {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth?.interface?._company || {};

  const generate_tooltip_labels_for_consumptions = (context, tooltipLabels) => {
    const label = context.dataset.label || '';
    const raw_value = context.raw || 0;

    const unit_label = tooltipLabels[context.datasetIndex]?.unit
      ? `${tooltipLabels[context.datasetIndex].unit}`
      : 'n/a';
    const context_array = tooltipLabels[context.datasetIndex];

    const value_of_context = context_array.value[context.dataIndex];

    // We can pass string or string[] to render multiple lines in tooltip
    return [
      `${label} : ${formatCurrency({
        number: raw_value,
        locale: company?.currency?.locale,
        currency: company?.currency?.code
      })}`, // eg: 'Eau : 12,34 €'
      `${t('consumptionsTooltip', { unit: unit_label, value: value_of_context })}` // eg: 'Consommation : 1234 m3'
    ];
  };

  return (
    <Page>
      <StatCard
        noRangePicker
        title={t('evolutionByTypeofMeterInEuros')}
        lineLabel={t('costs')}
        endpoint="meters/stats"
        formatYAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        is_overriden_tooltip_label
        format_tooltip_labels={generate_tooltip_labels_for_consumptions}
        formatDatasets={(data) => {
          const { meters, readings, pricings } = data;
          // 0. group readings by meter_id
          const readings_by_meter_id = group_readings_by_meter_id_and_interpolate_to_12_months(readings);
          // 1. sum up consumptions by tag_id and multiply by price
          const consumptions_by_tag_id = group_consumptions_by_key(readings_by_meter_id, pricings, 'tag_id');
          // 2. Return an array for each tag_id like [value, value, value, ...]
          const tag_datasets = generate_datasets_from_consumptions_by_key(consumptions_by_tag_id);
          // 3. Return an array of labels for each meter_id like [label_by_key, label_by_key, label_by_key, ...]
          const datasetsLabels = generate_datasetsLabels_from_consumptions_by_key(
            meters,
            consumptions_by_tag_id,
            'tag'
          );
          // 4. Generate labels
          const labels = generate_month_labels();
          // 5. Format datasets and fixe to 2 decimals
          const datasets = tag_datasets.map((dataset) => dataset.map((value) => Number(value).toFixed(2)));
          // 6. Format Tooltip labels with consumptions
          const sum_consumptions_by_tag_id = compute_sum_consumptions_by_key(readings_by_meter_id, 'tag_id');
          const tooltipLabels = generate_tooltipLabels_from_consumptions_by_key(
            consumptions_by_tag_id,
            meters,
            sum_consumptions_by_tag_id
          );

          return { datasets, datasetsLabels, labels, tooltipLabels };
        }}
        chart={LinesChart}
      />
      <StatCard
        noRangePicker
        title={t('evolutionByUsageInEuros')}
        lineLabel={t('costs')}
        endpoint="meters/stats"
        formatYAxis={(n) =>
          formatCurrency({
            number: n.toFixed(2),
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        }
        is_overriden_tooltip_label
        format_tooltip_labels={generate_tooltip_labels_for_consumptions}
        formatDatasets={(data) => {
          const { meters, readings, pricings } = data;
          // 0. group readings by meter_id
          const readings_by_meter_id = group_readings_by_meter_id_and_interpolate_to_12_months(readings);
          // 1. sum up consumptions by tag_id and multiply by price
          const consumptions_by_use_id = group_consumptions_by_key(readings_by_meter_id, pricings, 'use_id');
          // 2. Return an array for each tag_id like [value, value, value, ...]
          const use_datasets = generate_datasets_from_consumptions_by_key(consumptions_by_use_id);
          // 3. Return an array of labels for each meter_id like [label_by_key, label_by_key, label_by_key, ...]
          const datasetsLabels = generate_datasetsLabels_from_consumptions_by_key(
            meters,
            consumptions_by_use_id,
            'use'
          );
          // 4. Generate labels
          const labels = generate_month_labels();
          // 5. Format datasets and fixe to 2 decimals
          const datasets = use_datasets.map((dataset) => dataset.map((value) => Number(value).toFixed(2)));
          // 6. Format Tooltip labels with consumptions
          const sum_consumptions_by_use_id = compute_sum_consumptions_by_key(readings_by_meter_id, 'use_id');
          const tooltipLabels = generate_tooltipLabels_from_consumptions_by_key(
            consumptions_by_use_id,
            meters,
            sum_consumptions_by_use_id
          );

          return { datasets, datasetsLabels, labels, tooltipLabels };
        }}
        chart={LinesChart}
      />
      <StatCard
        noRangePicker
        title={t('distributionByTypeofMeterInEuros')}
        lineLabel={t('costs')}
        endpoint="meters/stats"
        formatDatasets={(data) => {
          const { meters, readings, pricings } = data;
          // 0. group readings by meter_id
          const readings_by_meter_id = group_readings_by_meter_id_and_interpolate_to_12_months(readings);
          // 1. sum up consumptions by tag_id and multiply by price
          const consumptions_by_tag_id = group_consumptions_by_key(readings_by_meter_id, pricings, 'tag_id');
          // 2. Return an array for each tag_id like [value, value, value, ...]
          const tag_datasets = generate_datasets_from_consumptions_by_key(consumptions_by_tag_id);
          // 3. Sum up all consumptions for each tag_id
          const sum_tag_datasets = tag_datasets.reduce(
            (acc, curr) => [...acc, curr.reduce((acc, curr) => (curr += acc), 0)],
            []
          );
          // 4. Format datasets and fixe to 2 decimals
          const datasets = sum_tag_datasets.map((value) => Number(value).toFixed(2));
          // 5. Return an array of labels for each meter_id like [label_by_key, label_by_key, label_by_key, ...]
          const labels = Object.keys(consumptions_by_tag_id).map(
            (key) => meters.find((meter) => meter.tag_id === key).tag.name
          );

          return { datasets, labels };
        }}
        chart={CamembertChart}
      />
      <StatCard
        noRangePicker
        title={t('distributionByUsageInEuros')}
        lineLabel={t('costs')}
        endpoint="meters/stats"
        formatDatasets={(data) => {
          const { meters, readings, pricings } = data;
          // 0. group readings by meter_id
          const readings_by_meter_id = group_readings_by_meter_id_and_interpolate_to_12_months(readings);
          // 1. sum up consumptions by tag_id and multiply by price
          const consumptions_by_use_id = group_consumptions_by_key(readings_by_meter_id, pricings, 'use_id');
          // 2. Return an array for each tag_id like [value, value, value, ...]
          const use_datasets = generate_datasets_from_consumptions_by_key(consumptions_by_use_id);
          // 3. Sum up all consumptions for each tag_id
          const sum_use_datasets = use_datasets.reduce(
            (acc, curr) => [...acc, curr.reduce((acc, curr) => (curr += acc), 0)],
            []
          );
          // 4. Format datasets and fixe to 2 decimals
          const datasets = sum_use_datasets.map((value) => Number(value).toFixed(2));
          // 5. Return an array of labels for each meter_id like [label_by_key, label_by_key, label_by_key, ...]
          const labels = Object.keys(consumptions_by_use_id).map(
            (key) => meters.find((meter) => meter.use_id === key).use.name
          );

          return { datasets, labels };
        }}
        chart={CamembertChart}
      />
    </Page>
  );
}
