import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import { ItemTypes } from 'constants/dnd';
import { useEffect, useState } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import classNames from 'utils/classNames';
import useDebouncedState from 'hooks/useDebouncedState';
import EntityPopoverFilter from 'components/filters/EntityPopoverFilter';
import SectionPicker from 'components/filters/SectionPicker';
import ListPopoverFilter from 'components/filters/ListPopoverFilter';
import Intervener from 'entities/Intervener/Intervener';
import { contractStates, ticket_states } from 'lists/contractStates';
import Scope from 'entities/Scope/Scope';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import axios from 'axios';
import { apiBaseURL } from 'index';
import Bookmark from 'components/filters/Bookmark';
import MenuItem from 'components/menu/MenuItem';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@material-ui/core';
import { dateToLocalFormat } from 'utils/dates';
import { AccessTime } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { Ecart } from 'entities/Maintenance/PeriodPreview';
import { useConfiguration } from 'hooks/useConfiguration';
import { truncateText } from 'utils/uiUtils';
import IconButton from 'components/IconButton';
import Calendar from 'modules/calendar/Calendar';
import Job from 'entities/Job/Job';
import Manager from 'entities/Manager/Manager';
import Client from 'entities/Client/Client';
import { priorities_options } from 'lists/priorities';
import { periods_options } from 'lists/periods_checked';
import EntityCreate from 'layouts/entities/EntityCreate';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import empty_base_64_image from 'utils/empty_base_64_image';
import { bubbleUpCells, bubbleDownCells } from 'modules/calendar/utils';
import VirtuosoList from 'components/ui/VirtualList';
import Tooltip from 'components/ToolTip';
import Ticket from 'entities/Ticket/Ticket';
import { useRole } from 'hooks/useRole';

export default function CalendarPage() {
  const [appliedFilters, setAppliedFilters] = useDebouncedState(initialValues);
  const [tickets, setTickets] = useState([]);
  const [resize, setResize] = useState(false);
  const [total_results, set_total_results] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();
  const auth = useAuth();
  const role = useRole();

  useEffect(() => {
    if (auth?.interface?.isContractor) {
      filters.unshift(
        <EntityPopoverFilter
          label="clientsTitle"
          entity={Client}
          path="filters.clients"
        />
      );
    }
  }, [auth?.interface]);

  const get_tickets = async (skip = 0) => {
    appliedFilters.limit = { startIndex: skip, stopIndex: skip + 30 };

    appliedFilters.filters = formatFiltersCalendar(appliedFilters.filters, auth);

    return axios
      .post(apiBaseURL + '/tickets/list', appliedFilters)
      .then((res) => {
        const tickets = res.data?.elements || [];
        setTickets((prev) => (skip === 0 ? tickets : [...prev, ...tickets]));
        set_total_results(res.data?.count || 0);
      })
      .catch((err) => console.log(err));
  };

  const has_access_drag = role.permission('tickets', 'visit_date') && role.permission('tickets', 'intervention_date');

  return (
    <>
      <EntityCreate
        in
        label={t('backToPreviousVersion')}
        icon={(className) => (
          <FAIcon
            collection={'fal'}
            icon={'clock-rotate-left'}
            className={className}
          />
        )}
        onClick={() => history.push('/calendar-legacy')}
      />

      <PageLayout>
        <div
          className={`lg:block h-[calc(100%-64px)] lg:h-full w-full  ${
            resize || !has_access_drag ? 'lg:w-full' : 'lg:w-3/4'
          } overflow-y-auto overflow-x-clip bg-white rounded-xl`}
        >
          <Calendar />
        </div>
        {has_access_drag && (
          <>
            {resize ? (
              <div className="hidden lg:flex lg:flex-col bg-white rounded-lg">
                <IconButton onClick={() => setResize((prev) => !prev)}>
                  <FAIcon
                    collection="far"
                    icon="chevron-right"
                    size="sm"
                  />
                </IconButton>
              </div>
            ) : (
              <div className={`hidden lg:flex lg:flex-col w-full lg:w-1/4`}>
                <FilterAndSearchBarForm
                  totalResults={total_results}
                  className=" bg-white rounded-t-xl drop-shadow z-[1]"
                  initialValues={initialValues}
                  onSubmit={(values) => setAppliedFilters(values)}
                  filters={filters}
                  withFilters
                  bookmarks={bookmarks}
                  resizeable
                  setResize={setResize}
                  menuItems={[
                    <MenuItem
                      icon="rotate-right"
                      label="refresh"
                      onClick={() => get_tickets()}
                    />
                  ]}
                />
                <VirtuosoList
                  fetchMore={get_tickets}
                  data={tickets}
                  item={TicketPreview}
                />
              </div>
            )}
          </>
        )}
      </PageLayout>
    </>
  );
}

function TicketPreview({ ...ticket }) {
  const { t } = useTranslation();
  const [openTicket, setOpenTicket] = useState(null);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.TICKET_PREVIEW,
    item: { ticket_id: ticket._id, title: ticket.title, description: ticket.description, number: ticket.number },
    collect: (monitor) => {
      const isDragging = monitor.isDragging();
      return { isDragging };
    }
  }));

  useEffect(() => {
    const node = document.getElementById(ticket._id);
    if (node) {
      node.addEventListener('dragstart', () => bubbleUpCells());
      node.addEventListener('dragend', () => bubbleDownCells());
    }
  }, []);

  const configuration = useConfiguration();
  const state = ticket?.state;
  const stateObject = contractStates[state];
  const deadlineExceed = !!ticket?.deadline && Date.now() > +new Date(ticket?.deadline.date);
  const theme = useTheme();

  const urgencies_config = configuration?.urgencies;
  const urgency =
    urgencies_config &&
    urgencies_config[ticket.urgency] &&
    !!urgencies_config[ticket.urgency].label?.length &&
    urgencies_config[ticket.urgency];

  const period = !!ticket?._period && ticket?._period.periods.find((p) => String(p._ticket) === String(ticket._id));

  return (
    <>
      <DragPreviewImage
        connect={preview}
        src={empty_base_64_image}
      />
      {openTicket && (
        <Ticket
          isDialog
          noFetch
          defaultOpenView
          childrenId={openTicket}
          childrenAuto
          afterDialogClose={() => setOpenTicket(null)}
        >
          <></>
        </Ticket>
      )}
      <div
        id={ticket._id}
        ref={drag}
        onClick={() => setOpenTicket(ticket._id)}
        className={classNames('p-3.5 border-b', isDragging ? 'opacity-50 cursor-grabbing' : 'cursor-grab')}
      >
        <div>
          {/* TICKET HEADER */}
          <div className="flex justify-between">
            <div className="flex items-center w-full mr-2">
              <div className="font-semibold text-xs flex items-center text-[#363640]">#{ticket.number}</div>
              <div
                className="font-semibold text-xs flex items-center ml-2"
                style={{ color: stateObject.color }}
              >
                {t(stateObject.label)}
                {ticket?.intervention_compute && (
                  <Tooltip
                    position={'right'}
                    title={t('intervenerOnTheSpot')}
                  >
                    <Box marginLeft="6px">
                      <FAIcon
                        style={{ color: '#f1c40f' }}
                        collection="fad"
                        icon="street-view"
                      />
                    </Box>
                  </Tooltip>
                )}
                {!!ticket?.deadline && (
                  <Tooltip
                    position="right"
                    title={
                      <>
                        {t('theBeforeDate')} {dateToLocalFormat(ticket?.deadline?.date, 'PPPPp')}
                        {
                          <>
                            <br />
                            {ticket?.deadline?.description}
                          </>
                        }
                      </>
                    }
                  >
                    <AccessTime
                      style={{
                        marginLeft: 6,
                        color: deadlineExceed ? theme.palette.primary.dark : theme.palette.secondary.light
                      }}
                    />
                  </Tooltip>
                )}

                {period && <Ecart period={period} />}
              </div>
            </div>
            <div className="flex items-center">
              {urgency && (
                <Chip
                  className="text-xs"
                  color="secondary"
                  style={{ color: urgency.color, borderColor: urgency.color }}
                  variant="outlined"
                  size="small"
                  label={t(urgency.label)}
                />
              )}
            </div>
          </div>

          {/* BODY TICKET */}
          <div className="mt-2 md:flex">
            <div className="overflow-hidden text-ellipsis w-full text-sm font-medium flex items-center">
              {!!ticket._period ? ticket._preventif?.name : ticket.title}
            </div>
            <div className="w-full flex items-center md:justify-end">
              {ticket._jobs?.length > 0 ? (
                <>
                  <FAIcon
                    icon="toolbox"
                    collection="fal"
                    size="small"
                    className="ml-[-4px]"
                  />
                  {ticket._jobs?.length === 1 ? ticket._jobs[0]?.name : `${ticket._jobs?.length} ${t('jobsS')}`}
                </>
              ) : null}
            </div>
          </div>

          <div className="flex justify-between items-center w-full mt-[6px] leading-4">
            <div>{truncateText(ticket.description, 160)}</div>
          </div>

          <div className="md:flex justify-between items-center mt-2">
            <div className="flex items-center">
              {ticket._locations?.length > 0 ? (
                <>
                  <FAIcon
                    icon="map-marker-alt"
                    collection="fal"
                    size="small"
                    className="ml-[-4px]"
                  />
                  {ticket._locations?.length === 1
                    ? `${ticket._locations[0]?.name || ''} ${ticket._locations[0].address?.postalCode || ''}`
                    : `${ticket._locations?.length} ${t('locationsS')}`}
                </>
              ) : null}
            </div>
            <div className="flex items-center">
              {ticket._equipments?.length > 0 ? (
                <>
                  <FAIcon
                    icon="wrench"
                    collection="fal"
                    size="small"
                    className="ml-[-4px]"
                  />
                  {ticket._equipments?.length === 1
                    ? `${ticket._equipments[0]?.name || ''}`
                    : `${ticket._equipments?.length} ${t('equipmentsS')}`}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PageLayout({ children }) {
  return (
    <div className="w-full h-full lg:h-[calc(100%-64px)] flex flex-col p-2">
      <div className="flex flex-row-reverse h-full min-h-0 gap-2">{children}</div>
    </div>
  );
}

const initialValues = {
  search: '',
  limit: { startIndex: 0, stopIndex: 30 },
  filters: {
    deleted: false,
    sort: {
      label: 'creationDateMostRecent',
      value: '_id',
      sort: -1,
      show: true
    },
    section: {
      open: true,
      closed: false,
      preventifs: true,
      withoutIntervener: false,
      immanager: false,
      deadline: false,
      nomanager: false
    },
    clients: [],
    interveners: [],
    states: [],
    typologies: [],
    locations: [],
    jobs: [],
    managers: [],
    categories: [],
    preventifs: [],
    preventif_checked: [],
    equipments: [],
    urgencies: [],
    bookmarks: ['toplan']
  }
};

export const formatFiltersCalendar = (filters, auth) => {
  const only_show_user_tickets = auth?.interface?._role?.permissions?.tickets?.includes('show_only_user_tickets');

  let _filters = structuredClone(filters); // Cloner l'objet pour éviter la mutation

  if (!filters.locations?.length && auth.interface._locations?.length)
    _filters['locations'] = auth.interface._locations;
  if (!filters.jobs?.length && auth.interface._jobs?.length) _filters['skills'] = auth.interface._jobs;
  if (!filters.clients?.length && auth.interface._scope_clients) {
    _filters['clients'] = auth.interface._scope_clients;
  }
  if (only_show_user_tickets) {
    _filters['section'] = {
      ..._filters['section'],
      immanager: true
    };
  }


  return _filters;
};

const filters = [
  <EntityPopoverFilter
    label="intervenersTitle"
    entity={Intervener}
    path="filters.interveners"
  />,
  <ListPopoverFilter
    label="Etat"
    options={ticket_states}
    path="filters.states"
  />,
  <EntityPopoverFilter
    label="types"
    entity={Scope}
    path="filters.typologies"
    entityProps={{
      type: 'ticketTypology',
      hiddenFilters: {
        only: [],
        parents: []
      }
    }}
  />,
  <EntityPopoverFilter
    label="locations"
    entity={Location}
    path="filters.locations"
  />,
  <EntityPopoverFilter
    label="jobsTitle"
    entity={Job}
    path="filters.jobs"
  />,
  <EntityPopoverFilter
    label="managersTitle"
    entity={Manager}
    path="filters.managers"
  />,
  <SectionPicker
    label="immanagerSection"
    path="filters.section.immanager"
    color="pink"
  />,
  <SectionPicker
    label="deadlineSection"
    path="filters.section.deadline"
    color="purple"
  />,
  <SectionPicker
    label="withoutAManager"
    path="filters.section.nomanager"
    color="orange"
  />,
  <ListPopoverFilter
    label="validatedPeriod"
    options={periods_options}
    path="filters.preventif_checked"
  />,
  <ListPopoverFilter
    label="priorities"
    options={priorities_options}
    path="filters.urgencies"
  />,
  <EntityPopoverFilter
    label="equipmentsTitle"
    entity={Equipment}
    path="filters.equipments"
  />
  // <EntityPopoverFilter label="maintenancesTitle" entity={Maintenance} path="filters.preventifs" />,
  // <SectionPicker label="Sans Intervenant" path="filters.section.withoutIntervener" color="red" />,
];

const bookmarks = [
  <Bookmark
    label="toPlan"
    path="filters.bookmarks"
    value="toplan"
    color="#ee754a"
  />,
  <Bookmark
    label="toReplan"
    path="filters.bookmarks"
    value="toreplan"
    color="#ce4413"
  />,
  <Bookmark
    label="waitingToBeReplaned"
    path="filters.bookmarks"
    value="waitingplan"
    color="#7f8c8d"
  />
];
