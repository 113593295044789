import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';

// Import all actions accounting
import {
  check_paid_invoices,
  setFactureComment,
  setFactureState,
  acceptDevis,
  removeInvoice,
  removeQuote,
  updateAccounting,
  removeAccounting,
  addInvoice,
  addQuote,
  addAccounting,
  setPriceToIntervention,
  linkTicketsToFacture
} from './accountingSlice';

import {
  finishElement as finishTicketOnAccounting,
  addAccounting as addAccountingByTicket,
  cancelIntervener,
  assignIntervener
} from './ticketsSlice';

export const accountingList = generateAsyncThunk({ type: 'POST', endpoint: 'ticket-list/accounting' });

const initialState = {
  tickets: [],
  count: 0,
  isLoading: false,
  isLoadingId: null
};

const updateTicketInState = (state, id, updatedElement) => {
  state.tickets = state.tickets.map((ticket) => (ticket._id === id ? { ...ticket, ...updatedElement } : ticket));
};

const accountingListSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(accountingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(accountingList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(accountingList.fulfilled, (state, action) => {
        const { tickets, count } = action.payload.data;
        state.tickets = tickets.map((ticket) => {
          const { _id: viewId, ...otherViewProps } = ticket?.views?.[0] || {};
          delete ticket.views;
          return { ...ticket, viewId, ...otherViewProps };
        });
        state.count = count || 0;
        state.isLoading = false;
      });

    const uniqueActionsWithElement = Array.from(
      new Set([
        setPriceToIntervention,
        linkTicketsToFacture,
        addAccounting,
        cancelIntervener,
        assignIntervener,
        addQuote,
        addInvoice,
        removeInvoice,
        removeQuote,
        updateAccounting,
        removeAccounting,
        acceptDevis,
        setFactureState,
        setFactureComment,
        check_paid_invoices,
        addAccountingByTicket
      ])
    );

    uniqueActionsWithElement.forEach((action) => {
      builder
        .addCase(action.pending, (state, action) => {
          const { id } = action.meta.arg?.params || action.meta;
          state.isLoadingId = id;
        })
        .addCase(action.rejected, (state) => {
          state.isLoadingId = null;
        })
        .addCase(action.fulfilled, (state, action) => {
          const { element, ticket } = action.payload.data;
          const { id } = action.meta;
          updateTicketInState(state, id, element || ticket);
          state.isLoadingId = null;
        });
    });

    builder.addCase(finishTicketOnAccounting.fulfilled, (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;
      updateTicketInState(state, id, { state: element.contract.state });
    });
  }
});

export default accountingListSlice.reducer;
