import { Box, Button, IconButton } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import React, { useState, createElement } from 'react';
import useFileViewer from 'hooks/useFileViewer';
import classNames from 'utils/classNames';
import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';
import { Visibility } from '@material-ui/icons';
import { FileActions } from '../files/FileActions';
import { KINGFISHER_DEMO_ACCOUNT_ID, KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID } from 'index';
import { useAuth } from 'hooks/useAuth';
import { useEntity } from 'contexts/entities/entityContext';
import { useContract } from 'contexts/entities/contractContext';
import { formatCurrency } from 'utils/formatCurrency';
import Tooltip from 'components/ToolTip';

const DevisValidation = ({ isLoading, unvalidated_quotes, actions, setReasonOpen }) => {
  const classes = useStyles();
  const { openFiles, setFilesActions } = useFileViewer();
  const { element } = useEntity();
  const auth = useAuth();
  const contract = useContract();
  const [isFakeDoubleValidationKingFisher, setFakeDoubleValidationKingFisher] = useState(false);

  const ticketPermissions = auth.interface._role?.permissions?.tickets || [];
  const validateDevisPermission = ticketPermissions.find((key) => /validate_devis_range/.test(key)) || '';

  const [valMin = '0', valMax = '0'] = validateDevisPermission.split(':')[1]?.split('-') || [];

  const valueMin = valMin === '0' ? 0 : parseInt(valMin);
  const valueMax = valMax === '0' ? Infinity : parseInt(valMax);

  const isKingFisherInvestmentRequest = KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID === element.category;
  const connectedCompanyId = auth.interface._company._id;
  const companyId = contract?.contractContractor?._company?._id;
  const company = auth.interface._company;

  const handleOpenFiles = (devis) => {
    const showAction = !(devis.price > valueMax || devis.price < valueMin);

    if (showAction)
      setFilesActions(() => () => (
        <FileActions
          company_id={companyId}
          actions={actions}
          devis={devis}
        />
      ));

    openFiles([devis._document]);
  };

  const shouldDisplayKingFisherInvestmentActions =
    !isFakeDoubleValidationKingFisher &&
    connectedCompanyId === KINGFISHER_DEMO_ACCOUNT_ID &&
    isKingFisherInvestmentRequest;

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        {createElement(shouldDisplayKingFisherInvestmentActions ? RenderInvestmentActionsKingFisher : RenderDevisList, {
          unvalidated_quotes,
          setFakeDoubleValidationKingFisher,
          isLoading,
          classes,
          handleOpenFiles,
          valueMax,
          valueMin,
          actions,
          setReasonOpen,
          company
        })}
      </Box>
    </Box>
  );
};

export default DevisValidation;

function RenderDevisList({
  unvalidated_quotes,
  company,
  isLoading,
  handleOpenFiles,
  classes,
  valueMax,
  valueMin,
  actions,
  setReasonOpen
}) {
  return unvalidated_quotes.map((devis, i) => (
    <RenderDevisActions
      devis={devis}
      index={i}
      company={company}
      isLoading={isLoading}
      handleOpenFiles={handleOpenFiles}
      classes={classes}
      valueMax={valueMax}
      valueMin={valueMin}
      actions={actions}
      setReasonOpen={setReasonOpen}
    />
  ));
}

function RenderInvestmentActionsKingFisher({ setFakeDoubleValidationKingFisher, isLoading, classes }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        onClick={() => setFakeDoubleValidationKingFisher(true)}
        disabled={isLoading}
        endIcon={
          <FAIcon
            collection="fal"
            icon="thumbs-down"
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionError)}
      >
        {t("Refuser l'investissement")}
      </Button>
      <Button
        onClick={() => setFakeDoubleValidationKingFisher(true)}
        disabled={isLoading}
        endIcon={
          <FAIcon
            collection="fal"
            icon="thumbs-up"
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionSuccess)}
      >
        {t("Valider l'investissement")}
      </Button>
    </Box>
  );
}

function RenderDevisActions({
  devis,
  company,
  isLoading,
  handleOpenFiles,
  classes,
  valueMax,
  valueMin,
  actions,
  setReasonOpen,
  index
}) {
  const { t } = useTranslation();

  return (
    <Box
      key={`${devis.reference}-quote-number-${index}`}
      marginTop={'10px'}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        marginBottom="6px"
        paddingRight="10px"
      >
        <strong>{`${devis.reference} / ${formatCurrency({
          number: devis.price,
          locale: company?.currency?.locale,
          currency: company?.currency?.code
        })}`}</strong>
      </Box>

      <Box className="flex items-center justify-end gap-2 right-0">
        <Tooltip
          position="top"
          title={t('openVerb')}
        >
          <IconButton
            disabled={isLoading}
            style={{ position: 'relative', left: 4 }}
            onClick={() => handleOpenFiles(devis)}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
        <Tooltip
          position="top"
          title={
            devis.price > valueMax || devis.price < valueMin ? (
              <Box>
                <strong>{t('access-denied-quote')}</strong>
              </Box>
            ) : (
              ''
            )
          }
        >
          <Box>
            <Button
              onClick={() => setReasonOpen(() => (reason) => actions.devis_validation.action(false, reason, devis._id))}
              disabled={isLoading || devis.price > valueMax || devis.price < valueMin}
              endIcon={
                <FAIcon
                  collection="fal"
                  icon="thumbs-down"
                  className={classes.viewActionIcon}
                />
              }
              color="secondary"
              variant="contained"
              className={classNames(classes.viewAction, classes.viewActionError)}
            >
              {t('decline')}
            </Button>
            <Button
              onClick={() => actions.devis_validation.action(true, null, devis._id)}
              disabled={isLoading || devis.price > valueMax || devis.price < valueMin}
              endIcon={
                <FAIcon
                  collection="fal"
                  icon="thumbs-up"
                  className={classes.viewActionIcon}
                />
              }
              color="secondary"
              variant="contained"
              className={classNames(classes.viewAction, classes.viewActionSuccess)}
            >
              {t('acceptQuote')}
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}
