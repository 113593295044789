import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { getInitialValuesFromFilters, getTicketFilters, LIMIT, ticketSortingOrder } from './baseFilters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useDebouncedState from 'hooks/useDebouncedState';
import { useConfiguration } from 'hooks/useConfiguration';
import { useSelector } from 'react-redux';
import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import { SkeletonTicket, TemplateNoTickets } from './TicketFile';
import PreviewRenderer from './Virtuoso/PreviewRenderer';
import { createFilterSetsManager, fetchTickets, generateFilters, trashMenuItems } from './utilsFun';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { TicketView } from './TicketView.js';
import { Close } from '@material-ui/icons';
import useNotifications from 'hooks/useNotifications';

const REMOVE_FILTER_PATH_TRASH = [
  'ongoing',
  'closed',
  'has_contractor',
  'has_skills',
  'late',
  'myInterventions',
  'alertExceeded',
  'has_managers',
  'preventifs'
];

// ticket list without checkable and maintainance filters
export default function TrashFile({ isModalOpen, setIsModalOpen, userMaintenance, calendar, scheduler }) {
  const config = useConfiguration();
  const notify = useNotifications();
  const { t } = useTranslation();
  const auth = useAuth();
  const { dispatch } = useAsyncDispatch();
  const [skip, setSkip] = useState(0);
  const [trashActiveTicket, setTrashActiveTicket] = useState(null);
  const listId = useMemo(() => Math.random().toString(36).substring(2, 15), []);
  const { count, tickets, isLoading, hasMore } = useSelector(
    (state) => state.ticketList[listId] || { tickets: [], count: 0, isLoading: false, hasMore: false }
  );

  const customFields = useSelector((store) => store.fieldSections).ticket;

  const showableTrashFilters = getTicketFilters({
    configuration: config,
    userMaintenance,
    calendar,
    scheduler,
    customFields,
    deleted: true
  }).filter((filter) => filter.show && !REMOVE_FILTER_PATH_TRASH.includes(String(filter.props.path)));

  // Gestion de l'état des filtres avec debounce
  const [initialValues, setInitialValues] = useState(
    getInitialValuesFromFilters({ filters: showableTrashFilters, deleted: true })
  );
  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues, 500);
  const filterSetsManager = createFilterSetsManager(appliedFilters);

  useEffect(() => {
    isModalOpen && _fetchTickets(debouncedAppliedFilters, false);
  }, [debouncedAppliedFilters, isModalOpen]);

  const _fetchTickets = useCallback(fetchTickets({ auth, dispatch, setSkip, skip, listId, notify }), [
    auth,
    dispatch,
    setSkip,
    skip,
    listId,
    notify
  ]);

  const filters = generateFilters(filterSetsManager.orderFilters, showableTrashFilters);

  // Création des éléments de menu et des composants de filtre
  const menuItems = trashMenuItems({ t, fetchTickets: _fetchTickets, appliedFilters, count });

  const closeTrashModal = () => {
    setIsModalOpen(false);
  };

  const endReached = useCallback(() => {
    if (hasMore) {
      _fetchTickets(appliedFilters, true);
    }
  }, [hasMore, _fetchTickets, appliedFilters]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeTrashModal}
      fullWidth
      maxWidth="sm"
    >
      <div className="w-full flex items-center justify-between h-12 mt-1">
        <DialogTitle>{t('trash')}</DialogTitle>
        <DialogActions>
          <div
            onClick={closeTrashModal}
            className="p-4 hover:bg-gray-200 cursor-pointer rounded-full "
          >
            <Close />
          </div>
        </DialogActions>
      </div>
      <div className="flex flex-col h-[80vh]">
        <FilterAndSearchBarForm
          className="mb-1"
          withFilters
          totalResults={count}
          initialValues={initialValues}
          setInitialValues={(filters) => {
            setInitialValues(filters);
            setAppliedFilters(filters);
          }}
          appliedFilters={appliedFilters}
          // onChange={setAppliedFilters}
          onSubmit={setAppliedFilters}
          menuItems={menuItems}
          filters={filters}
          dataFilters={showableTrashFilters.map((filter) => ({
            label: filter.props.label,
            key: filter.props.path
          }))}
          sortingOrders={ticketSortingOrder}
        />
        {isLoading ? (
          <SkeletonTicket />
        ) : !tickets?.length ? (
          <TemplateNoTickets scheduler={scheduler} />
        ) : (
          <PreviewRenderer
            entity="tickets"
            data={tickets}
            endReached={endReached}
            hasMore={hasMore}
            appliedFilters={appliedFilters}
            setActiveTicket={setTrashActiveTicket}
          />
        )}
      </div>
      <div className={`w-[57%] h-full`}>
        <TicketView
          isTrash
          ticketId={trashActiveTicket}
          setActiveTicket={setTrashActiveTicket}
          page={false}
        />
      </div>
    </Dialog>
  );
}
